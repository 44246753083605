<template>
  <b-container
    class="mt-5 pt-5"
    style="opacity: 0; position: absolute; z-index: -1; top: -100%"
  >
    <b-row
      id="print-me"
      class="justify-content-center pt-2"
      style="background-color: #f6f7fa"
    >
      <b-col id="contentPrintA5" ref="contentPrintA5" cols="12" lg="10">
        <b-container class="h-100 bg-white" style="letter-spacing: 1px">
          <div class="pt-5 px-lg-3 px-2 pb-3">
            <b-img
              v-if="
                (result.branch && result.branch.photo_url.includes('png')) ||
                result.branch.photo_url.includes('jpg')
              "
              :src="result.branch.photo_url"
              alt="logo"
              style="width: 100px"
            />

            <!-- <h6 class="size16 text-black mb-0 fw-bold-700">
              Struk Penjualan
            </h6> -->
            <b-row class="mt-4">
              <b-col lg="6" md="6" cols="6">
                <h6
                  class="size10 fw-bold-500 text-black mb-1"
                  v-if="result.branch"
                >
                  {{ result.branch.name || "-" }}
                </h6>
                <h6
                  class="size10 fw-bold-500 text-black mb-1"
                  v-if="result.branch"
                >
                  {{ result.branch.address || "-" }}
                </h6>
                <h6
                  class="size10 fw-bold-500 text-black mb-1"
                  v-if="result.branch"
                >
                  Tlp: {{ result.branch.phone }}
                </h6>
                <h6 class="size16 text-black mb-1" v-if="result.invoice_number">
                  No. Invoice: {{ result.invoice_number }}
                </h6>
                <h6 class="size16 text-black mb-1" v-if="result.po_so_number">
                  No. PO SO: {{ result.po_so_number }}
                </h6>
                <h6
                  class="size16 text-black mb-1"
                  v-if="deliveryNumbers.length"
                >
                  No. Surat Jalan: {{ deliveryNumbers.join(", ") }}
                </h6>
                <h6 class="size16 text-black mb-1" v-if="result.date">
                  Tanggal: {{ moment(result.date_original).format("DD/MM/YYYY") }}
                </h6>
              </b-col>
              <b-col lg="6" md="6" cols="6">
                <div class="mb-lg-2 mb-1">
                  <h6 class="size10 fw-bold-500 text-black mb-1">
                    Sales: {{ (result.seller && result.seller.name) || "-" }}
                  </h6>
                  <h6 class="size16 fw-bold-500 text-black mb-1">
                    Kasir:
                    {{ (result.operator && result.operator.name) || "-" }}
                  </h6>
                  <div class="d-flex" v-if="result.customer">
                    <h6 class="size16 fw-bold-600 text-black mb-1">
                      Pelanggan:
                    </h6>
                    <h6 class="size16 fw-bold-500 text-black mb-1 ml-1">
                      {{ result.customer.name }}
                    </h6>
                    <!-- <h6 class="size16 fw-bold-500 text-black mb-0 ml-1">
                       {{ result.customer.name }}
                    </h6> -->
                  </div>
                  <div class="d-flex" v-if="result.receiver_name">
                    <h6 class="size16 fw-bold-600 text-black mb-1">
                      Penerima:
                    </h6>
                    <h6 class="size16 fw-bold-500 text-black mb-1 ml-1">
                      {{ result.receiver_name }}
                    </h6>
                  </div>
                  <div class="d-flex" v-if="result.receiver_address">
                    <h6
                      class="size16 fw-bold-600 text-black mb-0"
                      style="opacity: 0.5; text-fill-color: transparent"
                    >
                      Penerima:
                    </h6>
                    <!-- <h6 class="size16 fw-bold-500 text-black mb-0" style="max-width: 60%;" v-html="sanitizeHTML(result.receiver_address)">
                    </h6> -->
                    <h6
                      class="size16 fw-bold-500 text-black mb-0"
                      style="max-width: 60%"
                    >
                      {{ result.receiver_address }}
                    </h6>
                  </div>
                  <!-- <div class="d-flex" v-if="result.receiver_address">
                    <h6 class="size16 fw-bold-600 text-black mb-0 opacity-0" style="opacity: 0;width: 4.8rem;">
                    </h6>
                    <h6 class="size16 fw-bold-500 text-black mb-0" style="width: 16rem;" v-html="result.receiver_address">
                    </h6>
                  </div> -->
                  <!-- <div class="d-flex" v-if="result.receiver_phone_number">
                    <h6 class="size16 fw-bold-600 text-black mb-0">
                      Telp: 
                    </h6>
                    <h6 class="size16 fw-bold-500 text-black mb-0 ml-1">
                       {{ result.receiver_phone_number }}
                    </h6>
                  </div> -->
                  <!-- <div class="d-flex" v-if="result.delivery_pickup === 'delivery'">
                    <h6 class="size16 fw-bold-600 text-black mb-0">
                      Pengiriman: 
                    </h6>
                    <h6 class="size16 fw-bold-500 text-black mb-0 ml-1 text-capitalize">
                       {{ result.delivery_pickup }}
                    </h6>
                  </div> -->
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1 ml-0" style="margin-right: 6rem">
              <b-col lg="12" class="pl-0">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr
                      class="border-bottom"
                      style="
                        border-bottom: 1px solid #000 !important;
                        border-top: 1px solid #000 !important;
                      "
                    >
                      <th
                        scope="col"
                        class="text-left"
                        style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                          width: 16rem;
                        "
                      >
                        Item
                      </th>
                      <th
                        scope="col"
                        class="text-right"
                        style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        "
                      >
                        Qty
                      </th>
                      <th
                        scope="col"
                        class="text-right"
                        style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        "
                      >
                        Harga Satuan
                      </th>
                      <th
                        scope="col"
                        class="text-right"
                        style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        "
                      >
                        Diskon Satuan
                      </th>
                      <th
                        scope="col"
                        class="text-right"
                        style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        "
                      >
                        Harga
                      </th>
                      <th
                        scope="col"
                        class="text-right"
                        style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        "
                      >
                        Jumlah
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in result.items" :key="index">
                      <td class="border-0 pb-0 pt-1">
                        {{ item.name }}
                      </td>
                      <td class="border-0 pb-0 pt-1 text-right">
                        {{ item.qty.toString().replace(".", ",") }}
                        {{ item.unit }}
                      </td>
                      <td class="border-0 pb-0 pt-1 text-right">
                        {{ item.sub_price | formatInvoice }}
                      </td>
                      <td class="border-0 pb-0 pt-1 text-right">
                        {{ item.discount_per_item | formatInvoice }}
                      </td>
                      <td class="border-0 pb-0 pt-1 text-right">
                        {{ item.price | formatInvoice }}
                      </td>
                      <td class="border-0 pb-0 pt-1 text-right">
                        {{ item.total | formatInvoice }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr class="my-1" style="border-top: 1px solid #000" />
                <b-row align-h="end">
                  <b-col cols="6" style="padding-right: 24px">
                    <div class="d-flex justify-content-between">
                      <h6 class="text-black size16 mb-0 fw-bold-700">
                        Sub Total
                      </h6>
                      <h6 class="text-black size16 mb-0 fw-bold-700">
                        {{ result.subtotal | formatAmount }}
                      </h6>
                    </div>
                    <div
                      v-if="result.discount_value > 0"
                      class="d-flex justify-content-between"
                    >
                      <h6 class="text-black size16 mb-0">Diskon Tambahan</h6>
                      <h6
                        v-if="result.discount_type == 'fixed'"
                        class="size16 text-black mb-0"
                      >
                        - {{ result.discount_value | formatAmount }}
                      </h6>
                      <h6
                        v-if="result.discount_type == 'percent'"
                        class="size16 text-black mb-0"
                      >
                        - {{ result.discount_value }}%
                      </h6>
                    </div>
                    <div
                      v-if="result.additional_fee_total > 0"
                      class="d-flex justify-content-between"
                    >
                      <h6 class="text-black size16 mb-0 fw-bold-700">
                        Total Biaya Tambahan
                      </h6>
                      <h6 class="text-black size16 mb-0 fw-bold-700">
                        {{ result.additional_fee_total | formatAmount }}
                      </h6>
                    </div>
                    <info-additional-cost :result="result" />
                    <!-- Split Payment -->
                    <hr
                      v-if="result.payments && result.payments.length > 1"
                      class="mb-25"
                      style="border-top: 1px solid #000"
                    />
                    <h6
                      class="text-black size10 fw-bold-700"
                      v-if="result.payments && result.payments.length > 1"
                    >
                      Pembayaran Terbagi
                    </h6>
                    <div
                      class="d-flex justify-content-between"
                      v-for="(payment, indexPayment) in result.payments"
                      :key="indexPayment"
                    >
                      <h6 class="text-black size10 mb-0 text-capitalize">
                        {{ paymentMethods(payment.payment_method) }}
                        {{
                          payment.payment_method == "transfer"
                            ? " ke " + payment.bank_account_payment.bank.name
                            : ""
                        }}
                      </h6>
                      <h6 class="text-black size10">
                        {{ parseFloat(payment.amount) | formatAmount }}
                      </h6>
                    </div>
                    <hr
                      v-if="result.payments && result.payments.length > 1"
                      class="mt-0 mb-25"
                      style="border-top: 1px solid #000"
                    />
                    <!-- Total Split Payment -->
                    <div
                      v-if="result.payments && result.payments.length > 1"
                      class="d-flex justify-content-between"
                      style="margin-bottom: 0.5rem"
                    >
                      <h6 class="text-black size10 mb-0">Total Pembayaran</h6>
                      <h6 class="size10 text-black mb-0">
                        {{
                          sumSplitPayment(result.payments) | formatAmountInvoice
                        }}
                      </h6>
                    </div>
                    <div
                      v-if="result.discount_total > 0"
                      class="d-flex justify-content-between"
                    >
                      <h6 class="text-black size10 mb-0 fw-bold-700">
                        Total Diskon
                      </h6>
                      <h6 class="text-black size10 mb-0 fw-bold-700">
                        {{ result.discount_total | formatAmount }}
                      </h6>
                    </div>
                    <div class="d-flex justify-content-between">
                      <h6 class="text-black size16 mb-0 fw-bold-700">
                        Grand Total
                      </h6>
                      <h6 class="text-black size16 mb-0 fw-bold-700">
                        {{ result.total | formatAmount }}
                      </h6>
                    </div>
                    <!-- <div
                      class="d-flex justify-content-between"
                    >
                      <h6 class="text-black size16 mb-0">
                        {{ paymentMethods(result.payment_method) }}
                      </h6>
                      <h6 class="text-black size16 mb-0">
                        {{ result.cash_total_payment | formatAmount }}
                      </h6>
                    </div> -->
                    <div
                      v-if="
                        result &&
                        result.payment_method !== 'piutang' &&
                        result.piutang &&
                        result.piutang.amount > 0
                      "
                      class="d-flex justify-content-between"
                    >
                      <h6 class="text-black size16 mb-0 text-capitalize">
                        Piutang
                      </h6>
                      <h6 class="text-black size16 mb-0">
                        {{
                          result.piutang && result.piutang.amount | formatAmount
                        }}
                      </h6>
                    </div>
                    <div
                      v-if="result.payment_method !== 'piutang'"
                      class="d-flex justify-content-between"
                    >
                      <h6
                        class="text-black size10 mb-0"
                        v-if="result.payment_method === 'wallet'"
                      >
                        Sisa Deposit
                      </h6>
                      <h6
                        class="text-black size10 mb-0"
                        v-if="result.payment_method === 'wallet'"
                      >
                        {{
                          result.payment_customer &&
                          result.payment_customer.wallet_balance
                            | formatAmountInvoice
                        }}
                      </h6>
                      <div
                        class="d-flex justify-content-between w-100 mb-25"
                        v-if="
                          (result.payment_method !== 'wallet' &&
                            result.payments &&
                            !result.payments.length > 1) ||
                          (result.returs_id && result.returs_id.length)
                        "
                      >
                        <h6 class="text-black size10 mb-0">Kembali</h6>
                        <h6 class="text-black size10 mb-0">
                          {{ result.cash_change | formatAmountInvoice }}
                        </h6>
                      </div>
                    </div>
                    <div
                      v-if="result.discount_total > 0"
                      class="d-flex justify-content-between"
                    >
                      <h6 class="text-black size16 mb-0">Anda Hemat</h6>
                      <h6 class="text-black size16 mb-0">
                        {{ result.discount_total | formatAmount }}
                      </h6>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col cols="6">
                    <h6 class="text-black size16">TTD Penerima</h6>
                  </b-col>
                  <b-col cols="6">
                    <h6 class="text-black size16 pl-2 text-center">
                      Hormat Kami
                    </h6>
                  </b-col>
                </b-row>
              </b-col>

              <b-col lg="12" class="pl-0 mb-0">
                <b-row>
                  <b-col lg="12" style="margin-top: 113px">
                    <h6 class="text-black size16 mb-0">Note:</h6>
                    <h6
                      class="text-black size16 mb-0 font-italic"
                      v-if="result.display_vat === 1"
                    >
                      *Harga barang sudah termasuk PPn 11%
                    </h6>
                    <h6 class="text-black size16 mb-0 font-italic">
                      {{ result.invoice_notes }}
                    </h6>
                    <h6
                      v-if="result.returs_id && result.returs_id.length > 0"
                      class="size16 text-black mt-4 mb-0"
                    >
                      {{ result.returs_id.join(", ") }}
                    </h6>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg } from "bootstrap-vue";
import moment from "moment";
import InfoAdditionalCost from "./InfoAdditionalCost.vue";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BContainer,
    InfoAdditionalCost,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
    merchant: {
      type: Object,
    },
    deliveryNumbers: {
      type: Array,
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    sumSplitPayment(payments) {
      let total = 0;
      for (let index = 0; index < payments.length; index++) {
        const element = payments[index];
        total += parseFloat(element.amount);
      }
      return total;
    },
    paymentMethods(method) {
      const paymentMapping = {
        cash: "Tunai",
        card: "Debit/Kredit",
        transfer: "Transfer",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
      };

      return paymentMapping[method] || "-";
    },
    sanitizeHTML(html) {
      return html?.replace(/<\/?p>/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";
.table thead tr th {
  font-weight: 800;
  color: #2b324f;
  font-size: 16px;
}
.table tbody tr td {
  font-weight: 500;
  color: #2b324f;
  font-size: 16px;
}
.bg-wave {
  background-image: url("../assets/images/wave-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  &.bg-white {
    background-color: #ffffff !important;
    border: 1px solid $light--3 !important;
    color: $dark !important;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}
</style>
