<template>
  <b-table
    :items="items"
    :fields="fields"
  />
  <!-- </b-table> -->
</template>

<script>
import {
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    items: {
      type: '',
    },
    fields: {
      type: '',
    },
  },
}
</script>
